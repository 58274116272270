<template>
  <div>
      <v-data-table
          :headers="headers"
          :items="items"
          dense
          :hide-default-footer="true"
          :disable-pagination="true"
          no-data-text="No schedules"
          :show-expand="false"
          :loading="isLoaded"
      >
          <template v-slot:item.delete="item">
            <v-tooltip bottom
                        nudge-bottom="5"
                        :disabled="$vuetify.breakpoint.xsOnly"
                        color="ycmd-black"
                        content-class="fade-0 pa-4 pt-2 pb-2">
                <template v-slot:activator="{ on }">
                  <v-btn icon dark
                          color="error"
                          v-on="on"
                          @click.stop="deleteSchedule(item.item)"
                          >
                    <v-icon :size="20">delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete Schedule</span>                
            </v-tooltip>
            <v-tooltip bottom
                        nudge-bottom="5"
                        :disabled="$vuetify.breakpoint.xsOnly"
                        color="ycmd-black"
                        content-class="fade-0 pa-4 pt-2 pb-2">
                <template v-slot:activator="{ on }">            
                  <v-btn icon dark
                          color="primary"
                          v-on="on"                          
                          @click.stop="runNow(item.item)"
                          >
                    <v-icon :size="20">send</v-icon>
                  </v-btn> 
                </template>
                <span>Run now</span>                
            </v-tooltip>

          </template>

      </v-data-table>
  </div>
</template>

<script>
import { format } from 'date-fns';

export default {
    name: 'datePicker',
    components: {
    },
    props: {
      schedules: {
        type: Array,
        default: () => []
      },
      height: {
        type: String,
        default: null
      },
      isLoaded: {
        type: Boolean,
        default: true
      },


    },
    data: () => ({
      dows: [
        {value: '0', name: 'Sun'}, 
        {value: '1', name: 'Mon'},
        {value: '2', name: 'Tues'}, 
        {value: '3', name: 'Wed'},
        {value: '4', name: 'Thur'},
        {value: '5', name: 'Fri'},
        {value: '6', name: 'Sat'}
      ],
      doms: [
        { name: '1st', value: '1' },
        { name: '2nd', value: '2' },
        { name: '3rd', value: '3' },
        { name: '4th', value: '4' },
        { name: '5th', value: '5' },
        { name: '6th', value: '6' },
        { name: '7th', value: '7' },
        { name: '8th', value: '8' },
        { name: '9th', value: '9' },
        { name: '10th', value: '10' },
        { name: '11th', value: '11' },
        { name: '12th', value: '12' },
        { name: '13th', value: '13' },
        { name: '14th', value: '14' },
        { name: '15th', value: '15' },
        { name: '16th', value: '16' },
        { name: '17th', value: '17' },
        { name: '18th', value: '18' },
        { name: '19th', value: '19' },
        { name: '20th', value: '20' },
        { name: '21st', value: '21' },
        { name: '22nd', value: '22' },
        { name: '23rd', value: '23' },
        { name: '24th', value: '24' },
        { name: '25th', value: '25' },
        { name: '26th', value: '26' },
        { name: '27th', value: '27' },
        { name: '28th', value: '28' },
        { name: '29th', value: '29' },
        { name: '30th', value: '30' },
        { name: '31st', value: '31' },
        { name: 'Last day of the month', value: 'Last' }]
    }),
    mounted() {
    },
    methods: {
      deleteSchedule (item) {
        this.$emit('deleteSchedule', item.id)
      },
      runNow (item) {
        this.$emit('runNow', item.id)
      }

    },
    computed: {
      headers () {
          let headers = [
              {
                  text: 'Repeat',
                  value: 'repeat',
                  width: '15px',
                  sortable: false
              },
              {
                  text: 'Day',
                  value: 'day',
                  width: '15px',
                  sortable: false
              },
              {
                  text: 'Time',
                  value: 'time',
                  width: '15px',
                  sortable: false
              },
              {
                  text: 'Parameters',
                  value: 'parameters',
                  width: '100px',
                  sortable: false
              },
              {
                  text: 'Emails',
                  value: 'emails',
                  width: '100px',
                  sortable: false
              },
              {
                  text: 'Last run',
                  value: 'last_run',
                  width: '100px',
                  sortable: false
              },
              {
                  value: 'delete',
                  text: '',
                  width: '30px',
                  sortable: false
              }
          ]
          return headers
      },
      items () {
          let arr = []

          if (this.schedules && this.schedules.length) {
              this.schedules.map(s => {

                let newS = {
                  id: s.schedule_id
                }

                switch (s.schedule_repeat) {
                  case 'daily':
                    newS.repeat = 'Daily'
                    newS.day = null
                    break        
                  case 'weekly':
                    newS.repeat = 'Weekly'
                    let dow = this.dows.find(d => {
                      return d.value == s.dow
                    })
                    if (dow) {
                      newS.day = dow.name
                    }
                    newS.dom = null
                    break        
                  case 'monthly':
                    console.log('this.doms', this.doms)
                 
                    newS.repeat = 'Monthly'
                    let dom = this.doms.find(d => {
                      return d.value == s.dom
                    })
                    newS.dow = null
                    if (dom) {
                      newS.day = dom.name
                    }
                    break        
                }
                newS.emails = s.emails ? s.emails.join(', '): ''
                newS.time = s.time

                let pars = ''

                if (s.parameters && s.parameters.length) {
                  s.parameters.map(p => {
                    if (p.value) {
                      if (pars) {
                        pars += ', '
                      }
                      pars += `${p.id}: ${p.formatted ? p.formatted : p.value}`
                    }
                  })
                }
                newS.parameters = pars

                if (s.last_run) {
                  newS.last_run = format(new Date(s.last_run), 'iii MMM d, h:mm a' )
                }

                arr.push(newS)
              })
          }

          return arr
      },
    },
    watch: {

    }
}
</script>
