import dataClient from '@/graphql/clients/axios';
import Vue from 'vue'
// Initial state
const state = {
  //pods: [],
  groupId: null,
  groupList: null,
  groups: {},
  facilities: {},
  facilitiesLoaded: false
};

const getters = {
  //getPods: state => state.pods,
  getGroupId: state => state.groupId,
  getfacilitiesLoaded: state => state.facilitiesLoaded,
  getGroupList: (state) => {
    return state.groupList
  },
  getGroupById: (state) => (id) => {
    return state.groups[id]
  },
  getFacilityById: (state) => (id) => {
    return state.facilities[id]
  }
};

const actions = {
  loadGroup: async ({commit, getters, dispatch, rootGetters}, id) => {

    let group = getters.getGroupById(id)

    if (!group) {

      let params = {
        operation: 'group_get',

        parameters: [
          {
            id: 'group_id',
            value: id
          }
        ]
      }
      
      let result = await dataClient('scheduleGet', params)

      let group = result.groups[0]
      group.users = result.users
      /*
      group.facilities = result.facilities
      group.facilityMap = {}
      group.facilities.map(f => {
        group.facilityMap[f.id] = f
      })
      */
      group.userMap = {}      
      group.users.map(f => {
        let map = {}
        f.roles.map(r => {
          map[r] = true
        })
        f.roleMap = map
        group.userMap[f.id] = f
      })

      commit('setGroup', group)

    }

    return getters.getGroupById(id)
  },
  loadGroupList: async ({commit, getters, dispatch, rootGetters}, payload) => {
    debugger
    let list = getters.getGroupList

    if (!list) {

      let params = {
        operation: 'group_get_list'
      }
      
      let result = await dataClient('scheduleGet', params)

      commit('setGroupList', result.groups)
    }
  },
  loadFacilities: async ({commit, getters, dispatch, rootGetters}, payload) => {

    if (!getters.getfacilitiesLoaded) {

      let params = {
        operation: 'facility_get'
      }
      
      let result = await dataClient('scheduleGet', params)

      let facilityMap = {}

      result.facilities.map(f => {
        facilityMap[f.id] = f
      })

      commit('setFacilities', facilityMap)
    }
  },
  savePod: async ({commit, getters, dispatch}, pod) => {
    debugger
    let parameters = []
    for (let prop in pod) {

      let val = pod[prop]
      if (val) {
        if (typeof(val) !== 'string') {
          val = JSON.stringify(val)
        }  
      }

      parameters.push({
        id: prop,
        value: val
      })
    }
    let params = {
      operation: 'pod_save',
      parameters
    }

    let result = await dataClient('scheduleOperation', params)
    console.log('result', result)

    if (result.error) {
      return result
    }

    if (result.pods[0]) {
      commit('updatePod', result.pods[0])
    }

    return result.pods &&  result.pods.length ? result.pods[0]: null 
  },
};

const mutations = {
  /*
  setPods (state, data) {
    state.pods = data;
  },
  */
  setGroupList (state, list) {
    state.groupList = list
  },
  setGroupId (state, id) {
    state.groupId = id
  },  
  setGroup (state, group) {
    state.groups[group.id] = group
  },
  setFacilities (state, map) {
    state.facilities = map
    state.facilitiesLoaded = true
  },
  updatePod (state, pod) {
    debugger
    let group = state.groups[pod.group_id]

    if (group) {
      let podIndex = group.pods.findIndex(p => {
        return p.id === pod.id
      })

      debugger
      if (podIndex >= 0) {
        Vue.set(group.pods, podIndex, pod);
      } else {
        Vue.set(group.pods, group.pods.length, pod);
      }
    }
  }       
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
