import gql from 'graphql-tag'

export default gql`query ycmdPatientGet($facility_id: ID, $page: Int, $limit: Int, $search: String, $mode: PatientGetMode, $user_id: ID, $sort: PatientSortField, $transferred_by_id: ID, $transferred_to_facility_id: ID, $transferred_from_facility_id: ID, $transferred_message_date: String ) {
	ycmdPatientGet(facility_id: $facility_id, page: $page, limit: $limit, search: $search, mode: $mode, user_id: $user_id, , sort: $sort, transferred_by_id: $transferred_by_id, transferred_to_facility_id: $transferred_to_facility_id, transferred_from_facility_id: $transferred_from_facility_id, transferred_message_date: $transferred_message_date )
    {
		total_count
		patients {
			id
			first_name
			last_name
			dob
			location {
				name
				shortcode
				room
				unit {
					name
					shortcode
					number
				}
			}
            assignments {
                group_id
                has_pod
                has_user
                is_group_only
                patient_id
                pod_id
                pod {
                    facility_ids
                    facilities {
                        name
                        id
                    }
                    group_id
                    id
                    name
                    user_ids
                    providers {
                        name
                        user_id
                    }
                    date_created
                }
                user_id
                user {
                    username
                    id
                }
            }
			transfer {

				date_received
				date_transferred
				from_facility_id
				from_facility {
					address
					id
					name
					shortcode
					units {
						established_patients_only
						unit_id
						name
						shortcode
					}
				}
				received_by_id
				received_by {
					id
					username
					first_name
					last_name
					middle_initial
					group_id
					email
				}
				to_facility_id
				to_facility {
					address
					id
					name
					shortcode
					units {
						established_patients_only
						unit_id
						name
						shortcode
					}
				}
				transferred_by_id
				transferred_by {
					id
					username
					first_name
					last_name
					middle_initial
					group_id
					email
				}

			}
		}
    }
}`
