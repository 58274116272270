import gql from 'graphql-tag'

export default gql`query scheduleGet($operation: String!, $parameters: [NameIdValueInput]) {
	scheduleGet(operation: $operation, parameters: $parameters )
    {
		  operation
      groups {
        id
        name
        facilities {
          name
          id
        }
        pods {
          facility_ids
          group_id
          id
          name
          user_ids
          providers {
            user_id
            name
            provider_id
          }
          date_created
        }
      }
      users {
        id
        first_name
        last_name
        group_id
        roles
		  }
      facilities {
        id
        name
        shortcode
      }
    }
}`
