<template>

  <div>
    <v-container
      fluid
      class="ycmd-dark-blue-radial-gradient full-width full-height pa-2 br-2"
    >
      <v-row>
        <v-col
          cols="12"
        >  
          <loading :active.sync="isLoading"
                  :can-cancel="false"
                  :is-full-page="false"
                  :background-color="`#101622`"
                  :color="`#637eb6`"
                  :loader="`bars`">
          </loading>
        </v-col>
      </v-row>
      <v-row
        align="center"
      >
        <v-col
          class="white--text"
        >  
          <div class="fs-16 fw-500">SECURE CODE</div>
        </v-col>
        <v-spacer />
        <v-col>
          <v-btn dark icon text @click="route('login', 'prev')" class="float-right">
            <v-icon>close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
        >  
          <div class="pa-2 fade-1 white--text">Please enter the code sent to you via SMS</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
        >  

          <v-text-field
                  dark solo dense flat
                  v-model="smsCode"
                  ref="input_smsCode"
                  name="input-smsCode"
                  label="Code"
                  v-on:keyup.enter="submit2FA"
                  :rules="[rules.required, rules.min]"
                  background-color="ycmd-dark-blue"
                  autocomplete="off"
                  hint="At least 5 characters" />

          <div v-if="mfaErrorMessage" style="background-color: red; color: white; padding: 5px;">{{mfaErrorMessage}}</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
        >  
          <v-btn dark block large depressed color="ycmd-light-blue"
                @click="submit2FA">Continue</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
        >  
          <v-btn dark text depressed color="white"
                @click="resend2fa">Resend Code</v-btn>
        </v-col>
      </v-row>
    </v-container>

      <v-dialog v-if="currentDialog === 'codeExpired'" persistent v-model="dialog" max-width="330" style="">

        <v-card color="" dark style="background-color: #09283F; padding-top: 15px;">
          <v-card-text class="fs-18">
            Your code has expired! Please log in again.
          </v-card-text>


          <v-card-actions>
            <v-spacer/>
            <v-btn text color="#FC5252" @click="() => {this.clearDialog(); this.route('login', 'fade-in-transition')}">Login</v-btn>
            <v-spacer/>
          </v-card-actions>
        </v-card>

      </v-dialog>

      <v-dialog v-if="currentDialog === 'pcpOnly'" persistent v-model="dialog" max-width="330" style="">

        <v-card color="" dark style="background-color: #09283F; padding-top: 15px;">
          <v-card-text class="fs-18">
            Please download our app YouCallMD in the iOS app store or Android play store.
          </v-card-text>


          <v-card-actions>
            <v-spacer/>
            <v-btn text color="#FC5252" @click="() => {this.clearDialog(); this.route('login', 'fade-in-transition')}">Back</v-btn>
            <v-spacer/>
          </v-card-actions>
        </v-card>

      </v-dialog>

      <v-dialog v-if="currentDialog === 'disabled'" persistent v-model="dialog" max-width="330" style="">

        <v-card color="" dark style="background-color: #09283F; padding-top: 15px;">
          <v-card-text class="fs-18">
            Your account has been disabled. Please contact your administrator for further assitance.
          </v-card-text>


          <v-card-actions>
            <v-spacer/>
            <v-btn text color="#FC5252" @click="() => {this.clearDialog(); this.route('login', 'fade-in-transition')}">OK</v-btn>
            <v-spacer/>
          </v-card-actions>
        </v-card>

      </v-dialog>

      <v-dialog v-if="currentDialog === 'noRoles'" persistent v-model="dialog" max-width="330" style="">

        <v-card color="" dark style="background-color: #09283F; padding-top: 15px;">
          <v-card-text class="fs-18">
            Your account has no roles. Please contact your administrator for further assitance.
          </v-card-text>


          <v-card-actions>
            <v-spacer/>
            <v-btn text color="#FC5252" @click="() => {this.clearDialog(); this.route('login', 'fade-in-transition')}">OK</v-btn>
            <v-spacer/>
          </v-card-actions>
        </v-card>

      </v-dialog>

  </div>

</template>

<script>

    import {mapActions, mapGetters} from "vuex";
    // Import component
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';

    import LOGIN_START from '../../graphql/mutations/authenticateBegin';
    import LOGIN_CONTINUE from '../../graphql/mutations/authenticateContinue';
    import { routeBasedOnRole } from '../../methods/globalMethods';
    import SubscriptionService from '@/methods/subscriptionInstance'

    export default {
        name: 'mfaCode',
        data: () => ({
            dialog: false,
            currentDialog: '',
            isLoading: false,
            smsCode: '',
            errorMessage: '',
            mfaErrorMessage: '',

            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 5 || 'Min 5 characters',
            }
        }),
        components: {
          Loading
        },
        mounted() {
          // Get other data from Vue-x
          const loginData = this.$store.getters['profile/getAuthenticateBegin'];

          // Check if other required data still exists in Vue-x
          if (!loginData.username || !loginData.challengeName || !loginData.session || !loginData.password) {
            this.route('login', 'fade-in-transition');
            return;
          }

          this.$nextTick(()=> {
            this.$refs.input_smsCode.focus()
          })
        },
        apollo: {
            $client: 'publicClient'
        },
        computed: {
          ...mapGetters('profile', ['getHasChat']),
        },
        methods: {
            ...mapActions('profile', ['setRoles', 'setProfile']),
            ...mapActions('rightMenu', ['openRightMenu', 'enableRightMenu', 'disableRightMenu']),
            ...mapActions('group', ['initGroups']),
            ...mapActions('chat', ['loadRooms']),
            async submit2FA() {
              // Get other data from Vue-x
              const loginData = this.$store.getters['profile/getAuthenticateBegin'];
              // console.log(loginData.username , loginData.challengeName, loginData.session);

              // Check if other required data still exists in Vue-x
              if (!loginData.username || !loginData.challengeName || !loginData.session) {
                this.route('login', 'fade-in-transition');
                return;
              }

              // // We are setting the accesstoken to null here because we don't want access token header to send for login
              // localStorage.setItem("accessToken", null);


              this.isLoading = true;

              try {
                  const response = await this.$apollo.mutate({
                      mutation: LOGIN_CONTINUE,
                      variables: {
                        email: loginData.username,
                        challengeName: loginData.challengeName,
                        session: loginData.session,
                        mfCode: this.smsCode
                      },
                      fetchPolicy: 'no-cache',
                      errorPolicy: 'all'
                  });
                  console.log('MFA response: ', response);

                  this.isLoading = false;
                  if (response.data.authenticateContinue) {
                      if (response.data.authenticateContinue.phoenixAuthenticate) {
                          this.$store.dispatch('app/init', {index: 0, itemIndex: 0}, {root: true});
                          
                          const {
                            token,
                            refresh_token,
                            first_name,
                            last_name,
                            user_id,
                            reference_id,
                            group_id,
                            group_name,
                            roles,
                            legacy_roles,
                            linked_groups,
                            linked_users,
                            settings,
                            online_status
                          } = response.data.authenticateContinue.phoenixAuthenticate;
                          // YCMD

                          // save phoenix token
                          localStorage.setItem("accessToken", response.data.authenticateContinue.phoenixAuthenticate.token)
                          // save refresh token
                          localStorage.setItem("refreshToken", response.data.authenticateContinue.phoenixAuthenticate.refresh_token)
                          // console.log('saving refresh token mfa: ', response.data.authenticateContinue.phoenixAuthenticate.refresh_token);
                          // initiate the subscription

                          // set profile VueX
                          await this.setProfile({
                              firstName: response.data.authenticateContinue.phoenixAuthenticate.first_name || 'N/A',
                              lastName: response.data.authenticateContinue.phoenixAuthenticate.last_name || 'N/A',
                              userID: response.data.authenticateContinue.phoenixAuthenticate ? response.data.authenticateContinue.phoenixAuthenticate.user_id : null,
                              referenceID: response.data.authenticateContinue.phoenixAuthenticate.reference_id,
                              group: {
                                id: response.data.authenticateContinue.phoenixAuthenticate.group_id ? response.data.authenticateContinue.phoenixAuthenticate.group_id : null,
                                name: response.data.authenticateContinue.phoenixAuthenticate.group_name ? response.data.authenticateContinue.phoenixAuthenticate.group_name : null,
                                voicemail: response.data.authenticateContinue.phoenixAuthenticate.group ? response.data.authenticateContinue.phoenixAuthenticate.group.voicemail: null,
                                forwarding: response.data.authenticateContinue.phoenixAuthenticate.group ? response.data.authenticateContinue.phoenixAuthenticate.group.forwarding: null,
                                settings: response.data.authenticateContinue.phoenixAuthenticate.group ? response.data.authenticateContinue.phoenixAuthenticate.group.settings: {}
                              },
                              roles: roles,
                              legacy_roles: legacy_roles,
                              role: 'Provider',
                              avatar: null,
                              settings: settings
                          });

                          let chatUid = this.getHasChat ? user_id: null
                          SubscriptionService.init({userID: chatUid, groupID: group_id});

                          if (this.getHasChat) {
                              // enable the menu

                              this.loadRooms()
                              this.enableRightMenu();
                          } else {
                              this.disableRightMenu();
                          }

                          // set groups
                          await this.initGroups({
                            myGroup: {
                              group_id: group_id,
                              group_name: group_name,
                              user_id: user_id,
                              settings: response.data.authenticateContinue.phoenixAuthenticate.group ? response.data.authenticateContinue.phoenixAuthenticate.group.settings: {}
                            },
                            groups: linked_groups || [],
                            myUser: { id: user_id,  roles: roles },
                            linkedUsers: linked_users || []
                          });

                          // check if they have no roles
                          if (!roles.length) {
                              this.currentDialog = 'noRoles';
                              this.dialog = true;
                              return;
                          }
                          console.log('route', this.$route)

/*
                          if (this.$route.query.redirect) {
                            this.$router.push(this.$route.query.redirect);
                          } else {
                            // Route based on roles
                            routeBasedOnRole(this.route);
                          }
*/                          
                      }
                      if (response.data.authenticateContinue.ycmdAuthenticate) {
                          //  PCP
                          if (response.data.authenticateContinue.ycmdAuthenticate.accessToken) {
                              // this.openRightMenu();
                              // save PCP token
                              localStorage.setItem("accessTokenPCP", response.data.authenticateContinue.ycmdAuthenticate.accessToken)
                              // save PCP refresh token
                              localStorage.setItem("refreshTokenPCP", response.data.authenticateContinue.ycmdAuthenticate.refreshToken)

                              // check if they have no roles
                              if (!response.data.authenticateContinue.ycmdAuthenticate.roles.length) {
                                  this.currentDialog = 'noRoles';
                                  this.dialog = true;
                                  return;
                              }

/*                              
                              if (this.$route.query.redirect) {
                                this.$router.push(this.$route.query.redirect);
                              } else {
                                // Route based on roles
                                routeBasedOnRole(this.route);
                              }
*/                              
                          }
                      }

                      if (!response.data.authenticateContinue.phoenixAuthenticate.token && !response.data.authenticateContinue.ycmdAuthenticate.accessToken) {
                          // user is disabled or no token came back
                          this.currentDialog = 'disabled';
                          this.dialog = true;
                          return;
                      }


                      if (this.$route.query.redirect) {
                        this.$router.push(this.$route.query.redirect);
                      } else {
                        // Route based on roles
                        routeBasedOnRole(this.route);
                      }


                  } else if (response.errors && response.errors.length > 0) {
                    // If an error came back
                    if (response.errors[0].message) {
                      // Check for special error messages
                      if (response.errors[0].errorType === 'NotAuthorizedException') {
                        // If your session has expired
                        this.currentDialog = 'codeExpired';
                        this.dialog = true;
                      }
                      this.mfaErrorMessage = response.errors[0].message;
                    } else {
                      this.mfaErrorMessage = "Error 2A. Invalid response.";
                    }
                  } else {
                    // data did not come back
                    console.log('loginStart data did not come back: ', response);
                    this.errorMessage = 'Authentication was not successful. Please try again.';
                  }

              } catch (e) {
                  this.isLoading = false;
                  this.errorMessage = e.message;
                  console.log('CAUGHT: There was an error logging in loginContinue: ', e);
              }

            },
            async resend2fa() {
              // Get other data from Vue-x
              const loginData = this.$store.getters['profile/getAuthenticateBegin'];

              this.mfaErrorMessage = '';
              this.isLoading = true;

              try {
                  const response = await this.$apollo.mutate({
                      mutation: LOGIN_START,
                      variables: {
                        email: loginData.username,
                        password: loginData.password
                      },
                      fetchPolicy: 'no-cache',
                      errorPolicy: 'all'
                  });
                  // console.log('login response: ', response);
                  //
                  this.isLoading = false;

                  if (response.data.authenticateBegin) {

                    this.$store.commit('profile/setAuthenticateBegin', {
                      session: response.data.authenticateBegin.Session,
                      challengeName: response.data.authenticateBegin.ChallengeName,
                      username: loginData.username
                    });

                    if (response.data.authenticateBegin.ChallengeName === 'NEW_PASSWORD_REQUIRED') {

                    } else {

                    }
                  } else if (response.errors && response.errors.length > 0) {

                  } else {
                    // data did not come back
                    console.log('loginStart data did not come back: ', response);
                  }
              } catch (e) {
                  this.isLoading = false;
                  console.log('CAUGHT: There was an error logging in *loginStart* : ', e);
              }
            },
            clearDialog() {
              this.dialog = false;
              this.currentDialog = '';
            },
            route(name, direction) {

                this.$router.push({
                    name: name, params: {
                        transition: direction
                    }
                }).catch(err => { console.log('router error: ', err) });
            }
        }
    }

</script>

<style scoped>



</style>
