<template>

    <div class="view">

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card">

            <div class="i-card__main">

                <div class="i-card__main__content br-2 pb-2 pr-2"
                >

                    <v-container
                        class="pa-0 ma-0 full-width"
                        style="width: 100%;max-width: 100%"
                    >
                        <v-row
                            class="pa-0 ma-0"
                        >
                            <v-col
                                class="pa-0 ma-0 shrink"                                
                            >

                                <v-autocomplete
                                        solo light dense flat outlined
                                        class="pr-3"
                                        v-model="selectedGroup"
                                        :disabled="!getGroupList || !getGroupList.length"
                                        :items="getGroupList"
                                        label="Select Group"
                                        item-value="id"
                                        item-text="name"
                                        style="max-width: 300px;min-width: 200px;"                                    
                                ></v-autocomplete>
                            </v-col>
                            <v-col
                                class="pa-0 ma-0 shrink"                                
                            >

                                <v-select
                                    light
                                    flat
                                    outlined
                                    solo
                                    class="align-center"
                                    :useIcon="false"
                                    label="Select Pod/Subgroup"
                                    :items="pods"
                                    v-model="selectedPod"
                                    item-value="id"
                                    item-text="name"                                    
                                    dense
                                    style="max-width: 300px;min-width: 234px;"                                    
                                >

                                    <template
                                        v-slot:append-outer
                                    >

                                        <v-tooltip 
                                                    bottom
                                                nudge-bottom="0"
                                                nudge-right="32"
                                                color="ycmd-black"
                                                :disabled="$vuetify.breakpoint.xsOnly"
                                                content-class="fade-0">
                                            <template v-slot:activator="{ on }">
                                                <v-btn icon
                                                    @click="addPod"
                                                        class="pa-0"
                                                        x-small
                                                    v-on="on">
                                                        <v-icon
                                                            color="white"
                                                            class="pa-0"
                                                        >
                                                            add
                                                        </v-icon>

                                                </v-btn>
                                            </template>
                                            <span>Add Pod</span>
                                        </v-tooltip>
                                        <v-tooltip 
                                            v-if="selectedPod"            
                                                    bottom
                                                nudge-bottom="0"
                                                nudge-right="32"
                                                color="ycmd-black"
                                                :disabled="$vuetify.breakpoint.xsOnly"
                                                content-class="fade-0">
                                            <template v-slot:activator="{ on }">
                                                <v-btn icon
                                                    @click="editPod"
                                                        class="pa-0"
                                                        x-small
                                                    v-on="on">
                                                        <v-icon
                                                            color="white"
                                                            class="pa-0"
                                                        >
                                                            edit
                                                        </v-icon>

                                                </v-btn>
                                            </template>
                                            <span>Edit Pod</span>
                                        </v-tooltip>


                                    </template>                                    
                                </v-select>

                            </v-col>
                        </v-row>
                    </v-container>



                </div>

            </div>

            <template slot="right-panel">

                <div>Hello right panel</div>

            </template>

            <template slot="bottom-panel">

                <div>Hello Bottom Panel</div>

            </template>

        </i-card>

        <pod 
            v-model="openPod"
            @cancel="podCancel"
            @ok="podSave"
            :groupId="selectedGroup"
            :podId="podId"
        />

    </div>

</template>


<script>
    import Pod from '@/views/apps/scheduling-new/components/pod.vue'

    import {mapActions, mapGetters} from "vuex";

    export default {
        name: 'redirect',
        components: {
            pod: Pod
        },
        data() {
            return {
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false },
                },
                group: {},
                selectedPod: null,
                openPod: false,
                podId: null
            }
        },
        created() {},
        activated() {},
        watch: {
            async getGroupId (newValue, oldValue) {
                if (newValue) {
                    this.group = await this.loadGroup(newValue)
                } else {
                    this.group = {}
                }
            }
        },
        methods: {
            ...mapActions('menuRight', ['openMenuRight','closeMenuRight','toggleMenuRight']),
            ...mapActions('schedule',['loadGroupList','loadGroup','loadFacilities','savePod']),
            addPod() {
                this.podId = null
                this.openPod = true
            },
            editPod() {
                this.podId = this.selectedPod
                this.openPod = true
            },

            podCancel() {
                debugger
                this.openPod = false
            },
            async podSave(pod) {
                debugger
                let result = await this.savePod(pod)
                debugger
                this.openPod = false
            }            
        },
        async mounted () {
            this.loadFacilities()
            await this.loadGroupList()

            if (this.getGroupId) {
                this.group = await this.loadGroup(this.getGroupId)
            }
        },
        computed: {
            ...mapGetters('schedule', ['v2SchedulingUrl', '']),
            ...mapGetters('schedule',['getGroupId','getGroupList']),
            selectedGroup: {
                get: function() {
                    return this.getGroupId;
                },
                set: function(newValue) {
                    this.$store.commit('schedule/setGroupId',newValue)
                }
            },   
            groupObject () {
                let obj = this.$store.getters['schedule/getGroupById'](this.selectedGroup)
                return obj ? obj : {}
            },                      
            pods () {

                if (this.group && this.group.pods && this.group.pods.length) {
                    return this.group.pods
                }

                return []
            }
        }
    }

</script>

<style scoped>

</style>
