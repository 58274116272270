import gql from 'graphql-tag'

export default gql`mutation scheduleOperation($operation: String!, $parameters: [NameIdValueInput]) {
	scheduleOperation(operation: $operation, parameters: $parameters )
    {
		operation
        pods {
          facility_ids
          group_id
          id
          name
          user_ids
          providers {
            user_id
            name
            provider_id
          }
          date_created
        }
    }
}`
