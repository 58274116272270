import './assets/transitions/transitions.styl'
import './assets/css/vuetify-extensions.css'
import './plugins/i-card/i-card.css'
import './assets/css/ellipsis-spinner.css'
import './assets/css/circle-spinner.css'
import './assets/css/helpers.css'
import './assets/css/colors.css'
import './assets/css/scroll-bar.css'
import './assets/css/vue-text-highlight.css'
import './assets/css/vue-text-highlight2.css'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import './assets/css/slide-animation.css'
import './assets/css/chat-bubble.css'
import './assets/css/app.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import 'roboto-fontface/css/roboto/roboto-fontface.css'

import './registerServiceWorker';

import Vue from 'vue'
import router from './router'
import routerRightMenu from './routerRightMenu'

import store from './store'
import App from './app.vue'
import RightMenu from './rightMenu.vue'
import {fadeInTransition} from "./assets/transitions/transitions";

import {createAuthLink} from 'aws-appsync-auth-link';

import {ApolloClient, ApolloLink, InMemoryCache, from, createHttpLink, HttpLink, split} from '@apollo/client/core';
import {getMainDefinition} from '@apollo/client/utilities';
import VueApollo from 'vue-apollo';

import VueWaveSurfer from './plugins/vue-wave-surfer';
import portalHeader from './plugins/portal/header/index';
import mainMenu from './plugins/portal/main-menu/index';
import iCard from './plugins/i-card/i-card';
import iAudioPlayer from './plugins/i-audio-player';

import vuetify from './plugins/vuetify';
import Toasted from 'vue-toasted';
import VueMask from 'v-mask';
import TextHighlight from 'vue-text-highlight';
import Vidle from 'v-idle'
import {onError} from "@apollo/client/link/error";

import phoenixClient from '@/graphql/clients/phoenix';
//import pcpClient from '@/graphql/clients/pcp';

Vue.use(VueApollo);
Vue.use(portalHeader);
Vue.use(mainMenu);
Vue.use(iCard);
Vue.use(iAudioPlayer);
Vue.use(VueWaveSurfer);
Vue.use(VueMask);
Vue.use(Vidle);
Vue.use(Toasted, {
  position: 'top-center',
  duration: 5000,
  fullWidth: true,
  fitToScreen: true
});

// Register global components
Vue.component('fade-in-transition', fadeInTransition);
Vue.component('text-highlight', TextHighlight);

const apiKey = process.env.VUE_APP_APPSYNC_API_KEY;

// console.log("********** TOKEN **********");
// console.log(localStorage.accessToken);
// console.log("**************************");

const httpLink = createHttpLink({
  uri: process.env.VUE_APP_APPSYNC_URL
});

const errorLink = onError(({graphQLErrors, networkError}) => {

  if (graphQLErrors)

    graphQLErrors.map(({ errorType, message, path }) => {

      console.error(`[GraphQL error]: ErrorType: ${errorType}, Message: ${ message }, Path: ${path}`)

    });

  // if (networkError) {
  //   console.error(`[Network error]: ${networkError}`);
  // }
});

const publicLink = ApolloLink.from([
  errorLink,
  createAuthLink({
    url: process.env.VUE_APP_APPSYNC_URL,
    region: process.env.VUE_APP_APPSYNC_REGION,
    auth: {
      type: 'API_KEY',
      apiKey: apiKey
    },
    disableOffline: true
  }),
  httpLink
]);

const publicClient = new ApolloClient({
  link: publicLink,
  cache: new InMemoryCache({addTypename: false})
});

Vue.config.productionTip = false;

new Vue({
  el: '#app',
  router,
  store,
  vuetify,
  apolloProvider: new VueApollo({
    clients: {
      publicClient,
      phoenixClient,
      //pcpClient
    },
    defaultClient: phoenixClient
  }),
  render: h => h(App)
});
/*
new Vue({
  el: '#rightMenu',
  router: routerRightMenu,
  store,
  vuetify,
  apolloProvider: new VueApollo({
    clients: {
      publicClient,
      phoenixClient
    },
    defaultClient: phoenixClient
  }),
  mounted() {
    this.$router.replace('/');
  },
  render: h => h(RightMenu)
});
*/