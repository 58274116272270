<template>
  <v-dialog
      v-model="value"
      persistent
      :max-width="maxWidth"
      >
      <v-form
          ref="frmValidation"
          v-model="isValid"
          style="width: 100%; height: 100%;"
          @submit.prevent="doSubmit"
      >      
      <v-card>
          <v-card-title class="text-h5">
            {{title}}
          </v-card-title>
          <v-card-subtitle>
            {{subtitle}}
          </v-card-subtitle>

          <v-card-text>
              <v-text-field
                        light solo dense flat outlined
                        v-model="name"
                        label="Name"
                        :rules=[rules.required]
                      />

              <v-select 
                        light solo dense flat outlined
                        v-model="selectedFacilities"
                        :items="allFacilities"
                        :menu-props="{ maxHeight: '400' }"
                        label="Select Facilities"
                        multiple
                        chips
                        item-text="name"
                        item-value="id"
                        :rules=[rules.arrayRequired]
                        :error-messages="facilityErrorMessages"
                      >
                        <template #selection="{ item }">
                          
                          <v-tooltip 
                            bottom
                            nudge-bottom="0"
                            nudge-right="32"
                            color="ycmd-black"
                            content-class="fade-0"
                            v-if="!item.level || item.level != 'group'"
                          >
                            <template v-slot:activator="{ on }">
                              <v-chip
                                color="ycmd-light-red"
                                v-on="on"
                                small
                                close
                                @click:close="close(item)"
                              >
                                {{item.name}}
                              </v-chip>
                            </template>
                            <span>{{item.error}}</span>
                          </v-tooltip>
                          <v-chip
                            color="ycmd-very-lightest-blue"
                            small
                            close
                            @click:close="close(item)"
                            v-else
                          >
                            {{item.name}}
                          </v-chip>
                      </template>
              </v-select>

              <v-select 
                        light solo dense flat outlined
                        v-model="selectedProviders"
                        :items="groupProviders"
                        :menu-props="{ maxHeight: '400' }"
                        label="Select Providers"
                        multiple
                        chips
                        item-text="name"
                        item-value="id"
                        :rules=[rules.arrayRequired]
                        :error-messages="providerErrorMessages"                        
                      >

                <template #selection="{ item }">
                  
                  <v-tooltip 
                    bottom
                    nudge-bottom="0"
                    nudge-right="32"
                    color="ycmd-black"
                    content-class="fade-0"
                    v-if="item.error"
                  >
                    <template v-slot:activator="{ on }">
                      <v-chip
                        color="ycmd-light-red"
                        v-on="on"
                        small
                        close
                        @click:close="closeProvider(item)"
                      >
                        {{item.name}}
                      </v-chip>
                    </template>
                    <span>{{item.error}}</span>
                  </v-tooltip>
                  <v-chip
                    color="ycmd-very-lightest-blue"
                    small
                    close
                    @click:close="closeProvider(item)"
                    v-else
                  >
                    {{item.name}}
                  </v-chip>
                </template>

              </v-select>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                type="submit"
                :disabled="!isValid"
              >
                OK
              </v-btn>
              <v-btn
                @click="cancel()"
              >
                Cancel
              </v-btn>              

          </v-card-actions>
      </v-card>
      </v-form>
  </v-dialog>

</template>

<script>

  export default {
    props: {
      value: {
        default: false,
        type: Boolean
      },
      'max-width': {
        default: '500',
        type: String
      },
      groupId: {
        default: '',
        type: String
      },
      podId: {
        default: '',
        type: String
      }   
    },
    data: () => ({
      selectedFacilities: [],
      selectedProviders: [],
      groupObject: {},
      podObject: null,
      name: '',
      rules: {
          required: value => !!value || 'Required.',
          arrayRequired: (value) => {
            return !value ||  !value.length ? 'Required.' : true
          }
      },
      isValid: true
    }),
    mounted() {
    },
    methods: {
      doSubmit () {
        debugger
        let pod = {
          name: this.name,
          group_id: this.groupObject.id,
          facilities: this.selectedFacilities,
          user_ids: this.selectedProviders
        }
        if (this.podObject) {
          pod.id = this.podObject.id
        }
        this.$emit('ok', pod)
      },
      cancel () {
        this.$emit('cancel','cancel')
      },
      close(item) {

        let index = this.selectedFacilities.findIndex(id => {
            return id === item.id
        })

        if (index >= 0) {
          this.selectedFacilities.splice(index, 1)
        }
      },
      closeProvider(item) {

        let index = this.selectedProviders.findIndex(id => {
            return id === item.id
        })

        if (index >= 0) {
          this.selectedProviders.splice(index, 1)
        }
      }
    },
    computed: {
      facilityErrorMessages () {
        if (!this.groupObject || !this.groupObject.id) {
          return []
        }

        let map = {}
        let facs = this.allFacilities
        facs.map(f => {
          map[f.id] = f
        })
        let errorMessage = []
        this.selectedFacilities.map(id => {
          let f = map[id]
          if (f.level === 'pod') {
            errorMessage = ['Facilities not in Group']
          }
        })
        return errorMessage
      },
      providerErrorMessages () {

        if (!this.groupObject || !this.groupObject.id) {
          return []
        }

        let map = {}
        let facs = this.groupProviders
        facs.map(f => {
          map[f.id] = f
        })
        let errorMessage = []
        this.selectedProviders.map(id => {
          let f = map[id]
          if (f.error) {
            errorMessage = ['Providers not in Group']
          }
        })
        return errorMessage
      },      
      subtitle () {
        return 'Add Subgroup/Pod'
      },
      title () {
        return 'Subgroup/Pod'
      },
      allFacilities () {
        if (!this.groupObject || !this.groupObject.id) {
          return []
        }

        let groupFacilities = {}

        this.groupObject.facilities.map(f => {
          groupFacilities[f.id] = "group"
        })

        if (this.podObject) {
          this.podObject.facility_ids.map(f => {
            if (!groupFacilities[f]) {
              groupFacilities[f] = "pod"
            }
          })
        }

        let arr = []
        for (let id in groupFacilities) {
          let level = groupFacilities[id]
          let f = this.$store.getters['schedule/getFacilityById'](id)

          if (f) {
            let newF = {
              name: f.name,
              id: f.id,
              level: level
            }

            if (newF.level === 'pod') {
              newF.error = 'Facility is not in group'
            }

            arr.push(newF)
          }
        }

        let sorted = arr.sort((a, b) => {
          return a.name.localeCompare(b.name);
        })

        return sorted
      },
      groupProviders () {
        console.log('this.groupObject', this.groupObject)

        let arr = []
        if (this.groupObject && this.groupObject.users && this.groupObject.users.length) {
          let temp = []
          this.groupObject.users.map(u => {
            if (u.roleMap['provider']) {

              let newU = {
                name: `${u.last_name}, ${u.first_name}`,
                id: u.id
              }

              if (u.group_id !== this.groupObject.id) {
                newU.error = 'Provider is not part of the group'
              }
              temp.push(newU)
            }
          })
          arr = temp.sort((a, b) => {
            return a.name.localeCompare(b.name);
          })
        }
        return arr
      },
      buttons () {
          return [
            {caption: 'OK'},
            {caption: 'Cancel'}
          ]
      },

    },
    watch: {
        value (newValue, oldValue) {
          if (newValue && this.groupId) {
            this.groupObject = this.$store.getters['schedule/getGroupById'](this.groupId)
          
            if (this.podId && this.groupObject && this.groupObject.pods && this.groupObject.pods.length) {
              let pod = this.groupObject.pods.find(p => {
                return p.id === this.podId
              })
              this.podObject = JSON.parse(JSON.stringify(pod)) //work with a copy

              if (this.podObject) {
                this.selectedFacilities = this.podObject.facility_ids
                this.selectedProviders = this.podObject.user_ids
                this.name = this.podObject.name
              }
            } else {
              this.podObject = null
              this.selectedFacilities = []
              this.selectedProviders = []
              this.name = ''
            }

          } else {
            this.groupObject = {}
          }
        }
    }
  }
</script>

