<template>

    <div class="i-card__panel i-card__panel__right">

        <div class="i-card__panel__header br-2">

            <div class="d-flex align-center flex-grow-1">

                <v-tooltip bottom
                           nudge-bottom="8"
                           nudge-right="40"
                           :disabled="$vuetify.breakpoint.xsOnly"
                           color="ycmd-black"
                           content-class="fade-0 pa-4 pt-2 pb-2">
                    <template v-slot:activator="{ on }">
                        <v-btn icon dark v-on="on" @click="closeAdvancedSearch" class="ml-2 mr-2">
                            <v-icon :size="20">close</v-icon>
                        </v-btn>
                    </template>
                    <span>Close Search</span>
                </v-tooltip>

                <div class="white--text fs-14 ml-3">
                    Advanced Search
                </div>
            </div>
            <div class="d-flex align-center justify-end ml-2">

              <v-btn dark small text
                     @click="clearSearch"
                     class="ycmd-light-blue ml-2 mr-2">
                Clear
              </v-btn>

                <v-btn dark small text
                       @click="submitAdvancedSearch({ shouldRefresh: true, closePanel: true });"
                       class="ycmd-light-blue mr-1">
                    Search
                </v-btn>

            </div>
        </div>

        <div class="i-card__panel__content white br-2 pa-2">

            <div class="i-card__scrollable">

                    <section-title :title="`Search Fields`"/>

                    <section-content>

                        <template v-slot:section-content>

                            <div>
                                <v-row>
                                    <v-col cols="12" sm="6" md="4">
                                      <div class="label-title pb-2">Facility</div>
                                      <ycmd-facility-search 
                                        :shouldIncludeFacilities="true"
                                        :facilitySearchSelected.sync="advancedSearchLocal.facility_id"
                                        :key="`fac_${refresh}_${localRefresh}`"
                                        :initialValue="advancedSearchLocal.facility_id"
                                        />
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                      <div class="label-title pb-2">From User</div>
                                      <ycmd-provider-search
                                        :providerSearchSelected.sync="advancedSearchLocal.from_user_id"
                                        :key="`ps_${refresh}_${localRefresh}`"
                                        :initialValue="advancedSearchLocal.from_user_id"
                                        />
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                      <div class="label-title pb-2">From User Role</div>
                                      <v-select light dense flat outlined
                                                v-model="advancedSearchLocal.from_role"
                                                :items="ycmdProviderRoles"
                                                item-text="name"
                                                item-value="value"
                                                background-color="white"
                                                return-object />
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                      <div class="label-title pb-2">To User</div>
                                      <ycmd-provider-search
                                        :providerSearchSelected.sync="advancedSearch.to_user_id"
                                        :key="`ps2_${refresh}_${localRefresh}`"
                                        :initialValue="advancedSearchLocal.to_user_id"                                                                                
                                        />
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                      <div class="label-title pb-2">Patient Type</div>
                                      <v-select light dense flat outlined
                                                v-model="advancedSearchLocal.patient_type"
                                                :items="ycmdPatientStatus"
                                                item-text="name"
                                                item-value="value"
                                                background-color="white"
                                                return-object />
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                      <div class="label-title pb-2">Confirmation Status</div>
                                      <v-select light dense flat outlined
                                                v-model="advancedSearchLocal.confirmation_status"
                                                :items="ycmdMessageConfirmedStatus"
                                                item-text="name"
                                                item-value="value"
                                                background-color="white"
                                                return-object />
                                    </v-col>
<!--                                    
                                    <v-col cols="12" sm="6" md="4">
                                        <div class="label-title d-flex justify-space-between align-end relative pb-2" style="margin-top: 0px;">
                                            Message Date
                                            <v-btn icon light @click="dialog = true"
                                                    v-if="advancedSearchLocal.messageDateRangeSelected && advancedSearchLocal.messageDateRangeSelected.name === 'Custom Range'"
                                                   style="position: absolute !important; right: -20px; top: -10px;">
                                                <v-icon :size="22" color="ycmd-blue">edit</v-icon>
                                            </v-btn>
                                        </div>
                                        <v-select light dense flat outlined
                                                v-model="advancedSearchLocal.messageDateRangeSelected"
                                                :items="messageDateRange"
                                                hide-details
                                                item-text="name"
                                                item-value="id"
                                                background-color="white"
                                                return-object />
                                    </v-col>


                                    <v-col cols="12" sm="6" md="4" v-if="advancedSearchLocal.messageDateRangeSelected && advancedSearchLocal.messageDateRangeSelected.name === 'Custom Range' && customDateRange && customDateRange.length && customDateRange[0]">
                                        <div class="label-title d-flex justify-space-between align-end relative" style="margin-top: 0px;">
                                            Start Time
                                        </div>
                                        <div class="label-title d-flex justify-space-between relative full-width white pa-2 br-3 mt-3" style="">
                                            <div>{{`${customDateRange && customDateRange.length && customDateRange[0] ? formatCustomDate(customDateRange[0]) : 'Not Selected'}`}}</div>
                                            <div class="mr-7">{{`${customTimeStart ? convertMillitaryTimeToAmPm(customTimeStart) : 'Not Selected'}`}}</div>
                                            <v-btn icon light @click="timePickerModels.start.display = !timePickerModels.start.display; timePickerModels.end.display = false;"
                                                   style="position: absolute !important; right: 0px; top: -2px;">
                                                <v-icon :size="22" color="ycmd-blue">schedule</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-col>

                                    <v-col cols="12" sm="6" md="4" v-if="advancedSearchLocal.messageDateRangeSelected && advancedSearchLocal.messageDateRangeSelected.name === 'Custom Range' && customDateRange && customDateRange.length && customDateRange[1]">
                                        <div class="label-title d-flex justify-space-between align-end relative" style="">
                                            End Time
                                        </div>
                                        <div class="label-title d-flex justify-space-between relative full-width white pa-2 br-3 mt-3" style="padding-right: 25px;">
                                            <div>{{`${customDateRange && customDateRange.length && customDateRange[1] ? formatCustomDate(customDateRange[1]) : 'Not Selected'}`}}</div>
                                            <div class="mr-7">{{`${customTimeEnd ? convertMillitaryTimeToAmPm(customTimeEnd) : 'Not Selected'}`}}</div>
                                            <v-btn icon light @click="timePickerModels.end.display = !timePickerModels.end.display; timePickerModels.start.display = false;"
                                                   style="position: absolute !important; right: 0px; top: -2px;">
                                                <v-icon :size="22" color="ycmd-blue">schedule</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-col>
-->                                    
                                    <v-col cols="12" sm="6" md="4">
                                        <div class="label-title d-flex justify-space-between align-end relative pb-2" style="margin-top: 0px;">
                                            Message Date
                                        </div>
                                        <date-range-picker
                                            v-model="advancedSearch.message_date"
                                            :includeCustomIcon="false"
                                            :key="`drp_${refresh}_${localRefresh}`"
                                            :datetype.sync="advancedSearch.datetype"
                                        />
                                    </v-col>

                                </v-row>

                                <v-row
                                    class="pa-0 ma-0 full-width"
                                    v-if="!nurseView && (!advancedSearchCustomFieldsLocal || !advancedSearchCustomFieldsLocal.length)"
                                >
                                    <v-col cols="12" sm="6" md="4" class="pa-0 ma-0 pt-4">
                                        <v-btn
                                            @click="doOpenSchedule"
                                            class="white--text ycmd-light-blue"                                            
                                        >
                                            scheduleObject
                                        </v-btn>
                                    </v-col>
                                </v-row>                                

                            </div>

                        </template>

                    </section-content>

                    <section-title v-if="!nurseView && advancedSearchCustomFieldsLocal && advancedSearchCustomFieldsLocal.length" :title="`Custom Group Fields`"/>

                    <section-content v-if="!nurseView && advancedSearchCustomFieldsLocal && advancedSearchCustomFieldsLocal.length">

                        <template v-slot:section-content>

                            <div>
                                <v-row>
                                    <v-col v-for="(field, i) in advancedSearchCustomFieldsLocal"
                                        cols="12"
                                        sm="6"
                                        md="6"
                                        :key="`csfield_${i}`"
                                    >
                                        <!-- <div class="label-title">{{item.label}}</div>
                                        <v-text-field light solo dense flat outlined
                                                      v-model="customFields[i]" /> -->

                                        <div class="d-flex flex-row align-center">
                                            <div class="label-title pb-2">{{field.label || 'Custom Field'}}</div>
                                            <v-spacer />
                                            <div class="label-title pb-2" style="font-size: 10px;">{{field.description || ''}}</div>
                                        </div>
                                        <v-select v-if="field.field_type === 'single_select'"
                                            light dense flat outlined
                                            v-model="field.value"
                                            placeholder="Select an option"
                                            :items="field.options"
                                            item-text="value"
                                            background-color="white"
                                            :key="`cf1_${field.id}_${refresh}_${localRefresh}`"
                                            return-object />
                                        <v-text-field v-else-if="field.field_type === 'input'"
                                            light dense flat outlined
                                            v-model="field.value"
                                            placeholder="Type answer here"
                                            background-color="white"
                                            :key="`cf2_${field.id}_${refresh}_${localRefresh}`"
                                            return-object />

                                    </v-col>
                                </v-row>
                                <v-row
                                    class="pa-0 ma-0"
                                >
                                    <v-col cols="12" sm="6" md="4" class="pa-0 ma-0">
                                        <v-btn
                                            @click="doOpenSchedule"
                                            class="white--text ycmd-light-blue"
                                        >
                                            Schedule
                                        </v-btn>
                                    </v-col>

                                </v-row>

                            </div>

                        </template>

                    </section-content>

                    <section-title v-if="!nurseView" :title="`Schedules`"/>

                    <section-content v-if="!nurseView">

                        <template v-slot:section-content>

                            <div
                            class="full-width"
                            >
                                <v-row
                                    class="pa-0 ma-0"                                    
                                >
                                    <v-col cols="12"
                                    class="pa-0 ma-0"
                                    >

                                        <schedules
                                            :schedules="messageSchedules"
                                             @deleteSchedule="deleteSchedule"
                                             @runNow="runNow"
                                             :isLoaded="schedulesLoading"
                                            height="200px"
                                        />
                                    </v-col>
                                </v-row>

                            </div>

                        </template>

                    </section-content>

            </div>

        </div>

        <v-dialog light v-model="dialog" max-width="300">

            <v-date-picker
                reactive scrollable
                v-model="customDateRange"
                range
            ></v-date-picker>

        </v-dialog>

        <v-dialog v-model="timePickerModels.start.display" max-width="470">

            <v-time-picker
                landscape
                v-model="customTimeStart"
                @click:minute=""
                style="margin-top: 10px;"
            ></v-time-picker>

        </v-dialog>

        <v-dialog v-model="timePickerModels.end.display" max-width="470">

            <v-time-picker
                landscape
                v-model="customTimeEnd"
                @click:minute=""
                style="margin-top: 10px;"
            ></v-time-picker>

        </v-dialog>
        <schedule v-model="openSchedule" @save="saveSchedule"
            :key="`schedule_${refreshSchedule}`"
        />

        <confirmation
            v-model="confirmScheduleDelete"
            title="Delete Schedule"
            subtitle="Confirm Schedule Delete"
            text="Are you sure you want to delete the schedule?"
            @click="confirmationClicked"
            :buttons="confirmationButtons"
            max-width="500"
        />        
        <confirmation
            v-model="confirmRunNow"
            title="Run Report"
            subtitle="Confirm Run report"
            text="Are you sure you want to run this report now?"
            @click="runNowConfirmationClicked"
            :buttons="confirmationButtons"
            max-width="500"
        />                
    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { format, parseISO, startOfDay, endOfDay, startOfMonth, endOfMonth, startOfWeek, endOfWeek, subDays } from 'date-fns';
    import { convertMillitaryTimeToAmPm } from '@/methods/utils'

    import { ycmdProviderRoles, ycmdPatientStatus, ycmdMessageConfirmedStatus } from '@/data/communications/constants';

    import SectionTitle from '@/components/sections/section-title';
    import SectionContent from '@/components/sections/section-content';

    import YCMDFacilitySearch from '@/components/searches/ycmd-facility';
    import YCMDProviderSearch from '@/components/searches/ycmd-provider';
    import Schedules from '@/components/schedules';
    import schedule from '@/components/modals/schedule'
    import ycmdReportScheduleCreate from '@/graphql/mutations/phoenix/ycmdReportScheduleCreate';
    import ycmdReportScheduleDelete from '@/graphql/mutations/phoenix/ycmdReportScheduleDelete';
    import Confirmation from '@/components/modals/confirmation.vue'
    import DateRangePicker from '@/components/datetime/date-range-picker';

    import dataClient from '@/graphql/clients/axios';

    export default {
        name: 'messagesAdvancedSearch',
        components: {
            'ycmd-facility-search': YCMDFacilitySearch,
            'ycmd-provider-search': YCMDProviderSearch,
            'section-title': SectionTitle,
            'section-content': SectionContent,
            'schedules': Schedules,
            schedule,
            'confirmation': Confirmation,
            'date-range-picker': DateRangePicker,
        },
        props: {
            advancedSearch: Object,
            advancedSearchCustomFields: Array,
            clearAdvancedSearch: Function,
            submitAdvancedSearch: Function,
            folder: String,
            inbox_mode: String,
            exclude_folders: Array,
            groupID: String,
            refresh: Number
        },
        apollo: {
            $client: 'phoenixClient'
        },
        data() {
            return {
                scheduleDeleteId: null,
                runNowId: null,
                format,
                parseISO,
                convertMillitaryTimeToAmPm,
                ycmdProviderRoles,
                ycmdPatientStatus,
                ycmdMessageConfirmedStatus,
                dialog: false,
                openSchedule: false,
                refreshSchedule: 0,
                confirmScheduleDelete: false,
                confirmRunNow: false,
                reportDefinition: {},
                sending: false,
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                timePickerModels: {
                    start: {
                        display: false
                    },
                    end: {
                        display: false
                    }
                },
                messageDateRange: [
                    {
                        name: 'Display All',
                        id: null,
                        start_date: null,
                        end_date: null
                    },
                    {
                        name: 'Last Week',
                        id: 'Last Week',
                        start_date: startOfWeek(subDays(new Date(), 7)),
                        end_date: endOfWeek(subDays(new Date(), 7))
                    },
                    {
                        name: 'This Week',
                        id: 'This Week',
                        start_date: startOfWeek(new Date()),
                        end_date: endOfWeek(new Date())
                    },
                    {
                        name: 'Yesterday',
                        id: 'Yesterday',
                        start_date: subDays(startOfDay(new Date()), 1),
                        end_date: subDays(endOfDay(new Date()), 1)
                    },
                    {
                        name: 'This Month',
                        id: 'This Month',
                        start_date: startOfMonth(startOfDay(new Date())),
                        end_date: endOfMonth(startOfDay(new Date()))
                    },
                    {
                        name: 'Custom Range',
                        id: 'Custom Range',
                        start_date: null,
                        end_date: null
                    }
                ],
                validAdvancedSearchForm: false,
                data: 0,
                localRefresh: 0,
                schedulesLoading: true
            }
        },
        activated() {

        },
        created() {
        },
        async mounted() {

            let def = await this.$store.dispatch('report/findReportDefinition', {report_id: 'messages'})
            this.reportDefinition = def
            this.localRefresh++
        },
        computed: {
            ...mapGetters('profile', ['getLegacyRoles']),
            ...mapGetters('report', ['getReportDefinitions','getReportSchedules']),
            confirmationButtons () {
                return [
                    {caption: 'OK', disabled: this.sending},
                    {caption: 'Cancel', disabled: this.sending}
                ]
            },
            messageSchedules () {

                let schedules = []
                if (this.getReportSchedules && this.getReportSchedules.length) {
                    this.getReportSchedules.map(s => {
                        if (s.report_id === 'messages') {
                            schedules.push(s.schedule)
                        }
                    })
                }

                this.schedulesLoading = !!!this.getReportSchedules

                return schedules

            },
            legacyRoleMap () {
                let map = {}
                let roles = this.getLegacyRoles
                console.log('roles', roles)
                if (roles && roles.length) {
                    roles.map(r => {
                        map[r] = true
                    })
                }
                return map
            },
            nurseView () {
                if (this.legacyRoleMap['provider']) {
                    return false
                }
                if (this.legacyRoleMap['member']) {
                    return false
                }
                if (this.legacyRoleMap['nurse']) {
                    return true
                }

                return false

            },
            advancedSearchLocal: {
                get: function(val) {
                    return this.advancedSearch;
                },
                set: function(newValue) {
                    this.$emit('update:advancedSearch', newValue)
                }
            },
            advancedSearchCustomFieldsLocal: {
                get: function(val) {
                    return this.advancedSearchCustomFields;
                },
                set: function(newValue) {
                    console.log('settings back up: ', newValue);
                    this.$emit('update:advancedSearchCustomFields', newValue)
                }
            },
            customTimeStart: {
                get: function() {
                    return this.advancedSearch.customTimeStart
                },
                set: function(val) {
                    this.advancedSearchLocal.customTimeStart = val;
                }
            },
            customTimeEnd: {
                get: function() {
                    return this.advancedSearch.customTimeEnd
                },
                set: function(val) {
                    this.advancedSearchLocal.customTimeEnd = val;
                }
            },
            customDateRange: {
                get: function() {
                    return this.advancedSearch.customDateRange
                },
                set: function(val) {
                    let dates = Array.from(val);
                    const sortedDates = dates.sort((a, b) => {
                        return (a < b) ? -1 : ((a > b) ? 1 : 0);
                    });
                    const sortedDatesRange = {
                        start_date: (sortedDates.length === 1 || sortedDates[0] === sortedDates[1]) ? startOfDay(new Date(sortedDates[0].replace(/-/g, '\/'))) : (startOfDay(new Date(sortedDates[0].replace(/-/g, '\/'))) || null),
                        end_date: (sortedDates.length === 1 || sortedDates[0] === sortedDates[1]) ? endOfDay(new Date(sortedDates[0].replace(/-/g, '\/'))) : (endOfDay(new Date(sortedDates[1].replace(/-/g, '\/'))) || null)
                    }
                    // messageDateRangeSelected will keep format for sending to API
                    this.advancedSearch.messageDateRangeSelected = {
                        name: 'Custom Range',
                        ...sortedDatesRange
                    }
                    console.log('setting custom range: ', sortedDatesRange);
                    // customDateRange will keep format for v-date-picker
                    this.advancedSearchLocal.customDateRange = dates;
                }
            }
        },
        watch: {
            dialog(val) {
                if (val === false) {
                    // the date picker modal was closed
                    if (this.customDateRange && this.customDateRange.length) {
                        // if we selected the first date but not the last
                        if (this.customDateRange[0] && !this.customDateRange[1]) {
                            this.customDateRange = [this.customDateRange[0], this.customDateRange[0]]
                        }
                    }
                }
            },
            'advancedSearch.messageDateRangeSelected'(val) {
                // console.log('new val: ', val);
                if (val && val.name === 'Custom Range') {
                    this.dialog = true;
                }
            },
            advancedSearchCustomFieldsLocal: {
                handler: function (val, oldVal) {
                    console.log('emitting: ', val);
                    this.$emit('update:advancedSearchCustomFields', val)
                },
                deep: true
            }
        },
        methods: {
            doOpenSchedule () {
                let errorMessage
                if (!this.advancedSearch.message_date) {
                    errorMessage = 'Message Date is required to schedule a report'
                }                
                if (errorMessage) {
                    this.$toasted.error(errorMessage)
                } else {
                    this.openSchedule = true
                }
            },
            async runNowConfirmationClicked (btn) {

                if (btn.caption === 'OK') {

                    this.sending = true
                    let run = await dataClient('ycmdReportRun', {schedule_id: this.runNowId})
                    if (run.job_id) {
                        this.$toasted.success(`Report submitted`);
                    } else {
                        let error = 'An error has occurred'
                        if (run && run.error && run.error.message) {
                            error = run.error.message
                        }
                        this.$toasted.error(error);

                    }
                }
                this.confirmRunNow = false
            },
            async confirmationClicked (btn) {
                if (btn.caption === 'OK') {
                    this.sending = true
                    let response = await this.$apollo.mutate({
                        mutation: ycmdReportScheduleDelete,
                        variables: {schedule_id: this.scheduleDeleteId},
                        client: 'phoenixClient',
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    })

                    console.log('response', response)
                    if (response && response.errors && response.errors.length) {
                        this.$toasted.error(`Error: ${response.errors[0].message}`);
                    } else {
                        await this.$store.commit('report/removeSchedule',  
                        {
                            report_id: 'messages',
                            schedule_id: this.scheduleDeleteId
                        })
                        this.$toasted.success('Schedule deleted');
                    }
                    this.sending = false
                }
                this.confirmScheduleDelete = false
            },            
            async saveSchedule (schedule) {
                let scheduleObject = {
                    report_id: 'messages',
                    schedule_repeat: schedule.repeat,
                    dow: schedule.dow,
                    dom: schedule.dom,
                    time: schedule.time,
                    parameters: [],
                    format: 'xls',
                    emails: schedule.emails
                }

                let parameters = {}
                /*
                parameters.inbox_mode = this.inbox_mode
                parameters.folder = this.folder
                parameters.exclude_folders = this.exclude_folders.join('|')
                parameters.group_id = this.groupID
                */
                let payload={
                    folder: this.folder,
                    parameters
                }
                this.$emit('getMessageParameters', payload)

                parameters.facility_id = (this.advancedSearch.facility_id && this.advancedSearch.facility_id.facility) ? this.advancedSearch.facility_id.facility : null
                parameters.facility_unit_shortcode = (this.advancedSearch.facility_id && this.advancedSearch.facility_id.shortcode) ? this.advancedSearch.facility_id.shortcode : null
                parameters.from_user_id = this.advancedSearch.from_user_id ? this.advancedSearch.from_user_id.id : null
                parameters.from_role = this.advancedSearch.from_role ? this.advancedSearch.from_role.value : null
                parameters.to_user_id = this.advancedSearch.to_user_id ? this.advancedSearch.to_user_id.id : null
                parameters.patient_type = this.advancedSearch.patient_type ? this.advancedSearch.patient_type.value : null
                parameters.confirmation_status = this.advancedSearch.confirmation_status ? this.advancedSearch.confirmation_status.value : null

                //parameters.start_date = (this.advancedSearch.messageDateRangeSelected && this.advancedSearch.messageDateRangeSelected.start_date) ? this.advancedSearch.messageDateRangeSelected.start_date : null
                //parameters.end_date = (this.advancedSearch.messageDateRangeSelected && this.advancedSearch.messageDateRangeSelected.end_date) ? this.advancedSearch.messageDateRangeSelected.end_date : null
                parameters.message_date = this.advancedSearch.message_date
                console.log('parameters', parameters)
                console.log('advancedSearch', this.advancedSearch)

                if (this.advancedSearchCustomFieldsLocal) {
                    for (let item in this.advancedSearchCustomFieldsLocal) {
                        let currentItem = this.advancedSearchCustomFieldsLocal[item];
                        //console.log('currentItem: ', currentItem);
                        if (currentItem.id && currentItem.value) {
                            parameters[`dynamic|${currentItem.value}`] = currentItem.value.id || currentItem.value
                        }
                    }
                }
                for (let p in parameters) {
                    scheduleObject.parameters.push({
                        id: p,
                        value: parameters[p]
                    })
                }
                

                let response = await this.$store.dispatch('report/scheduleSave', {
                    schedule: scheduleObject
                })

                if (response && response.status === 'success') {
                    this.$toasted.success('Schedule saved');
                    this.refreshSchedule++
                } else {
                    this.$toasted.error(`An error has occurred`);
                }

            },
            runNow (id) {
                this.sending = false
                this.runNowId = id
                this.confirmRunNow = true
            },

            deleteSchedule (schedule) {
                this.scheduleDeleteId = schedule
                this.confirmScheduleDelete = true
            },
            route(name, transition) {

              this.$router.push({
                name: name, params: {
                  transition: transition
                }
              }).catch(err => { console.log('router error: ', err) });
            },
            formatCustomDate(input) {
                // converts yy/mm/dd to mm/dd/yy
                const array = (input || '').toString().split(/\-/g);
                array.push(array.shift());
                return array.join('-') || null;
            },
            clearSearch() {
                this.clearAdvancedSearch();
                this.submitAdvancedSearch({ shouldRefresh: false, closePanel: false });
            },
            closeAdvancedSearch() {
                // update on parent
                this.submitAdvancedSearch({ shouldRefresh: false, closePanel: true });
            },
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            }
        }
    }

</script>

<style scoped>

    .item-box {
        background-color: #fff;
        border-bottom: #177ad5 solid 1px;
    }

    .item-box.odd {
        background-color: #ECEFF1;
    }

    .item-box:hover {
        background-color: rgba(255, 255, 255, 0.8)
    }

</style>
